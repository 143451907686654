/* eslint-disable no-console */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Agregadores } from './Agregadores';
import { useDataTable } from './context/DataTableContext';
import {
  Table,
  TableContainer,
  TableHeader,
  Tbody,
  Td,
  Tr,
  TrAgregadores,
  TrHeader,
} from './styles';
import { TableHeaderCell } from './TableHeaderCell';
import { AgregadorTipo, DataTableProps, HeadersProps } from './types';
import { agregadorLabel } from './utils/agregadorLabel';
import { formatValue } from './utils/formatValue';
import { useSearch } from '../Search/SearchContext';
import { getColorLine, getColorOdd, getColorText } from '~/utils/getGridColors';

export const DataTableContent: React.FC<DataTableProps> = ({
  columns,
  rows,
  loading,
  components,
  totals,
  onRowClick,
  handleSort,
  sortCampos,
}) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [heightFromBody, setHeightFromBody] = useState(0);
  const [widthTr, setWidthTr] = useState<number>(0);
  const trRef = useRef<HTMLTableRowElement | null>(null);

  const tableHeaderRef = useRef<HTMLTableSectionElement | null>(null);

  const { dynamicWidth } = useDataTable();

  const { filtersDaTela, loadingTable } = useSearch();

  const LoadingOverlayComponent = components?.LoadingOverlay ?? (() => null);
  const PaginationComponent = components?.Pagination ?? (() => null);

  const agregadores = columns.filter(
    (column) => column.tipo_agregador_tot !== AgregadorTipo.NENHUM,
  );

  const agregadoresPorCampo = new Map<number, HeadersProps>();

  agregadores.forEach((agregador) => {
    agregadoresPorCampo.set(agregador.codCampo, agregador);
  });

  const items = rows.length;
  const noRecordsFound = items <= 0 && !loading;
  const showAgregadores = !loading && agregadores.length > 0 && items > 0;

  useEffect(() => {
    if (loading && loadingTable) {
      setScrollPosition(0);
      setHeightFromBody(0);
    }
  }, [loading, loadingTable]);

  const handleScroll = useCallback(() => {
    setScrollPosition(0);

    if (tableHeaderRef.current) {
      const headerPosition =
        tableHeaderRef.current.getBoundingClientRect().top + window.scrollY;

      if (headerPosition >= heightFromBody) setScrollPosition(window.scrollY);
    }
  }, [heightFromBody]);

  const isValidHex = (hexColor: string) =>
    /^#([0-9A-F]{3}|[0-9A-F]{6})$/i.test(hexColor);

  const getColorOrDefault = useCallback((item: any): string => {
    const DEFAULT_COLOR = '#FFFFFF';
    const hexColor = String(item.color).toUpperCase();

    return isValidHex(hexColor) ? hexColor : DEFAULT_COLOR;
  }, []);

  const getColorWithFallback = useCallback(
    (color: string, fallbackColor: string): string => {
      return isValidHex(color) ? color : fallbackColor;
    },
    [],
  );

  const sumWidths = useCallback((arry: HeadersProps[]) => {
    return arry.reduce((total, column) => {
      return total + (column.width || 0);
    }, 0);
  }, []);

  useEffect(() => {
    setHeightFromBody(0);
    const tableHeader = tableHeaderRef.current;

    if (tableHeader) {
      const bodyTop = document.body.getBoundingClientRect().top;
      const tableHeaderTop = tableHeader.getBoundingClientRect().top;
      const heightFromBodyToTableHeader = tableHeaderTop - bodyTop;

      const heigth =
        heightFromBodyToTableHeader === 0 ? 321 : heightFromBodyToTableHeader;
      const heigthFix = heigth >= 500 ? 286 : heigth;

      if (heigthFix > 0) setHeightFromBody(heigthFix);
    }
  }, [window.location.href, filtersDaTela, loadingTable, sortCampos]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [heightFromBody]);

  useEffect(() => {
    const updateWidth = () => {
      if (trRef.current) {
        const browserWidth = window.innerWidth;
        const elementWidth = trRef.current.offsetWidth;

        setWidthTr(Math.min(elementWidth, browserWidth));
      }
    };

    updateWidth();

    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  return (
    <div style={{ border: '0.0625rem solid #e0e0e0' }}>
      <TableContainer count={items}>
        <Table>
          <TableHeader
            ref={tableHeaderRef}
            style={{ top: `${Math.max(scrollPosition - heightFromBody, 0)}px` }}
          >
            <TrHeader>
              {columns.map((column, idx) => (
                <TableHeaderCell
                  key={column.codCampo}
                  column={column}
                  isLast={idx === columns.length - 1}
                  handleSort={handleSort}
                  sortCampos={sortCampos}
                />
              ))}
            </TrHeader>
          </TableHeader>
          {loading && <LoadingOverlayComponent />}

          <Tbody count={items}>
            {!loading && noRecordsFound && (
              <p className="noRecordsFound">Nenhum registro encontrado...</p>
            )}

            {rows.map((row, idx) => {
              const hex = getColorOrDefault(row);

              const colorLine = getColorWithFallback(
                getColorLine(hex, 0.09, idx),
                '#FFFFFF',
              );
              const colorText = getColorWithFallback(
                getColorText(hex, 0.09, idx),
                '#000000',
              );
              const colorHover = getColorWithFallback(
                getColorOdd(hex, 0.15),
                '#F5F5F5',
              );

              return (
                <Tr
                  key={row.id || idx}
                  bgColor={colorLine}
                  bgHover={colorHover}
                  ref={trRef}
                >
                  {columns.map((column, index) => {
                    const isLast = index === columns.length - 1;

                    const calculatedWidth = (() => {
                      if (isLast && columns.length < 5) {
                        const checkWidth = widthTr === 0 ? 1170 : widthTr;
                        const calc = checkWidth - sumWidths(columns);
                        return calc + column.width;
                      }
                      return dynamicWidth[column.codCampo] ?? column.width;
                    })();

                    return (
                      <Td
                        key={column.codCampo}
                        headers={column.headerName}
                        onClick={() => onRowClick({ row })}
                        width={calculatedWidth}
                        color={colorText}
                      >
                        {formatValue(row[column.field], column)}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}

            {showAgregadores && (
              <TrAgregadores count={items}>
                {columns.map((col) => {
                  const agregadorInfo = agregadoresPorCampo.get(col.codCampo);
                  const field = agregadorInfo?.field;
                  const total = field ? totals[field] : undefined;
                  const label = agregadorLabel(
                    Number(agregadorInfo?.tipo_agregador_tot),
                  );

                  return (
                    <Agregadores
                      key={col.codCampo}
                      agregadoresPorCampo={agregadoresPorCampo}
                      col={col}
                      total={total}
                      label={label}
                    />
                  );
                })}
              </TrAgregadores>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <PaginationComponent />
    </div>
  );
};
